import type { MeQuery } from "@generated/graphql";

export function isUserAlphaOrBeta(meData?: MeQuery["me"] | undefined): boolean {
  if (!meData) {
    return false;
  }

  return (
    meData.roles.find(
      (r) => r.toLowerCase().includes("alpha") || r.toLowerCase().includes("beta"),
    ) !== undefined
  );
}

export function isUserGamma(meData?: MeQuery["me"] | undefined): boolean {
  if (!meData) {
    return false;
  }

  return meData.roles.find((r) => r.toLowerCase().includes("gamma")) !== undefined;
}
