import { useSurvey } from "@infinitaslearning/survey";
import { useBrand } from "./use-brand";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useRouter } from "next/router";
import { useFeatureFlag } from "./use-feature-flag";
import { getApmWeb } from "@lib/observability";
import { useSubmissionDate } from "./use-submission-date";
import { useContext, useMemo } from "react";
import { UserContext } from "@components/user-context/user-context";
import { getTallyToken } from "@lib/tally/get-token";

export const useFeedbackForm = () => {
  const user = useContext(UserContext);
  const me = user?.me?.data;
  const analytics = getAnalyticsTracker();
  const router = useRouter();
  const { locale } = useBrand();
  const apm = getApmWeb();

  const jsonNPS = useFeatureFlag<string>("tallyFormNps", "{}");
  const jsonCSATQ = useFeatureFlag<string>("tallyFormCSATQ", "{}");
  const jsonCSATU = useFeatureFlag<string>("tallyFormCSATU", "{}");

  const configNPS = useMemo(() => JSON.parse(jsonNPS), [jsonNPS]);
  const configCSATQ = useMemo(() => JSON.parse(jsonCSATQ), [jsonCSATQ]);
  const configCSATU = useMemo(() => JSON.parse(jsonCSATU), [jsonCSATU]);

  const { savedSubmissions, setSubmissions } = useSubmissionDate({
    configNPS,
    configCSATQ,
    configCSATU,
  });

  const loadingDependencies =
    !me?.id || !configNPS?.formId || !configCSATQ?.formId || !configCSATU?.formId;

  useSurvey({
    platform: "PEP",
    userId: me?.id ?? "",
    surveys: [configNPS, [configCSATQ, configCSATU]],
    analytics,
    apm,
    autoOpen: true,
    loadingDependencies,
    location: router.pathname,
    savedSubmissions,
    onAnswered: setSubmissions,
    fetchToken: getTallyToken,
    popupOptions: {
      hiddenFields: {
        userId: me?.id,
        organizationId: me?.organizationId,
        language: locale,
      },
      emoji: {
        text: "👋",
        animation: "wave",
      },
    },
  });
};
