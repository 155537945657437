import { useMemo } from "react";
import { configureWebObservability } from "@infinitaslearning/observability-web";
import { isPlatformBrowser } from "@utils/common";
import packageJson from "../../../package.json";

import type { Env } from "@utils/common";
import type { WebObservability } from "@infinitaslearning/observability-web";

type SharedInputParams = {
  serviceName: string;
  serviceVersion: string;
};
type InputParams = SharedInputParams &
  (
    | {
        environment: "local";
        serverUrl: string;
        serverUrlPrefix: undefined;
        sendCredentials: boolean;
      }
    | {
        environment: "test" | "staging" | "production";
      }
  );

// This is just a fake object to prevent SSR from crashing and to prevent the need of adding
// additional `if / ts checks` everywhere we want to use stuff returned by this file
const mockApmWeb = {
  captureError: () => {},
  setUserContext: () => {},
  setContext: () => {},
};

// Define singleton for observability web
// @ts-expect-error we set to the mock for SSR (or if disabled), as it won't work server-side or when disabled
let apmWeb: WebObservability | null = process.env.NODE_ENV === "test" ? mockApmWeb : null;

const SERVER_URL_LOCAL_DEV =
  "https://a2ccf4ce09324b819578f6253260eca7.apm.westeurope.azure.elastic-cloud.com:443";

function getInitParams(env: Env): InputParams {
  // this must be the same of the package.json, this way we end up with just
  // one service in Elastic
  const serviceName = packageJson.name;
  const serviceVersion = process.env.NEXT_PUBLIC_IMAGE_VERSION || "local-version";

  const sharedParams: SharedInputParams = {
    serviceName,
    serviceVersion,
  };

  if (env === "local") {
    // for local development we have some additional stuff
    return {
      ...sharedParams,
      environment: env,
      serverUrl: SERVER_URL_LOCAL_DEV,
      serverUrlPrefix: undefined,
      sendCredentials: false,
    };
  }

  return {
    ...sharedParams,
    environment: env,
  };
}

const isObservabilityEnabled = process.env.NEXT_PUBLIC_ENABLE_OBSERVABILITY === "true";

export const initObservabilityWeb = (environment: Env) => {
  if (!isObservabilityEnabled || !isPlatformBrowser()) {
    console.log("Observability web not enabled or ssr - skipping initialization");
    // @ts-expect-error we set to the mock for SSR (or if disabled), as it won't work server-side or when disabled
    apmWeb = mockApmWeb;
    return;
  }

  // if not initialized already, initialize observability web
  if (!apmWeb) {
    const initParams = getInitParams(environment);

    apmWeb = configureWebObservability(initParams);
  }
};

/**
 * Use this to track errors in other js modules (not components)
 */
export const getApmWeb = (): WebObservability => {
  if (!apmWeb) {
    throw new Error("Observability web not initialized");
  }

  return apmWeb;
};

/**
 * Use this to track errors in react components
 */
export const useObservabilityWeb = (): {
  // TODO: maybe we can just return the apmWeb object here instead of wrapping it
  apmWeb: WebObservability;
} => {
  if (!apmWeb) {
    throw new Error("Observability web not initialized");
  }

  // we memoize this because it's always the same instance
  const memoApmWeb = useMemo(() => {
    // biome-ignore lint/style/noNonNullAssertion: we're sure it's non-null, otherwise an error was thrown
    return apmWeb!;
  }, []);

  return {
    apmWeb: memoApmWeb,
  };
};
