export const INFINITAS_DOMAIN = "@infinitaslearning.com";

export const INTERNAL_DOMAINS = [
  INFINITAS_DOMAIN,
  "@plantyn.com",
  "@noordhoff.nl",
  "@liber.se",
  "@midiharmonica.com",
  "@knowledgemd.com",
  "@spacehotline.com",
  "@valanides.com",
  "@pretreer.com",
  "@sinaite.net",
  "@danubiusinfo.hu",
];

export const OBSERVABILITY_TRANSACTION_SAMPLE_RATE = 0.04;
