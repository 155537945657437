import isPropValid from "@emotion/is-prop-valid";
import { styled } from "@infinitaslearning/pixel-design-system";

type LoadingSize = "small" | "large";

type LoadingProps = {
  backgroundColor: string;
  size?: LoadingSize;
};

export const LoadingContainer = styled("div")<LoadingProps>`
  grid-column: 1/13;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CircularProgress = styled("div", {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<LoadingProps>`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: rotate 1s linear infinite;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.pixel.color.palette.primary[30]} 0%, ${theme.pixel.color.palette.primary[80]} 100%)`};
  border-radius: 50%;
  height: ${({ size }) => (size ? (size === "small" ? "20px" : "80px") : "80px")};
  width: ${({ size }) => (size ? (size === "small" ? "20px" : "80px") : "80px")};

  &::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(
          ${(props: LoadingProps) => props.backgroundColor},
          ${(props: LoadingProps) => props.backgroundColor}
        )
        0% 0%,
      linear-gradient(360deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
  }

  &::after {
    content: "";
    position: absolute;
    background: ${(props: LoadingProps) => props.backgroundColor};
    border-radius: 50%;
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
  }
`;
