import { type SxProps, useTheme } from "@infinitaslearning/pixel-design-system";
import type React from "react";
import { useEffect, useState } from "react";

import { CircularProgress, LoadingContainer } from "./loading.styles";

type LoadingSize = "small" | "large";

export type LoadingProps = {
  backgroundColor?: string;
  size?: LoadingSize;
  timeBeforeShowMs?: number;
  overlay?: boolean;
};

export const Loading: React.FC<LoadingProps> = ({
  backgroundColor,
  size,
  overlay,
  timeBeforeShowMs = 500,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const theme = useTheme();
  // set default background color is not set
  if (!backgroundColor) {
    backgroundColor = theme.pixel.color.background.key;
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowLoading(true);
    }, timeBeforeShowMs);

    return () => clearTimeout(timeOut);
  }, [timeBeforeShowMs]);

  // Don't show loading if it only will appear briefly
  if (!showLoading) return null;

  const overlayStyling: SxProps = {
    position: "absolute",
    opacity: 0.5,
    zIndex: 100,
    isolation: "isolate",
  };

  const defaultBackgroundColor = theme.pixel.color.background.key;

  return (
    <LoadingContainer
      sx={overlay ? overlayStyling : {}}
      data-testid="loading-screen"
      backgroundColor={backgroundColor ?? defaultBackgroundColor}
      size={size}
    >
      <CircularProgress backgroundColor={backgroundColor ?? defaultBackgroundColor} size={size} />
    </LoadingContainer>
  );
};
