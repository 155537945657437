import type { NextPageWithLayout } from "@pages/types";
import { TeacherPlatform } from "@styles/global-styling/global.styles";

type LoggedOutViewProps = {
  Component: NextPageWithLayout;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  pageProps: any;
};

export const LoggedOutView = ({ Component, pageProps }: LoggedOutViewProps) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return <TeacherPlatform>{getLayout(<Component {...pageProps} />)}</TeacherPlatform>;
};
