import EvaluationLicenceProvider from "@components/evaluation-licence-context/evaluation-licence-context";
import { UmcProvider } from "@components/umc-context/umc-provider";
import { UserProvider } from "@components/user-context/user-context";
import { WebSocketClientProvider } from "@components/web-socket-context/web-socket-client-provider";
import { useClarityTags } from "@lib/clarity/useClarityTags";
import { useUsetiful } from "@lib/usetiful/useUsetiful";
import { ClarityScript } from "@components/clarity-script";
import type { NextPageWithLayout } from "@pages/types";
import { TeacherPlatform } from "@styles/global-styling/global.styles";

import { useFeedbackForm } from "@utils/use-feedback-form";
import { useTrackExperiments } from "@lib/analytics-tracker/use-experiments";

const clarityProjectId = process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID;

type LoggedInViewProps = {
  Component: NextPageWithLayout;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  pageProps: any;
};

const UserComponents = ({ Component, pageProps }: LoggedInViewProps) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  useTrackExperiments();
  useFeedbackForm();
  useUsetiful();
  const customTags = useClarityTags();

  return (
    <WebSocketClientProvider>
      <ClarityScript projectId={clarityProjectId} customTags={customTags} />
      <TeacherPlatform>{getLayout(<Component {...pageProps} />)}</TeacherPlatform>
    </WebSocketClientProvider>
  );
};

export const LoggedInView = ({ Component, pageProps }: LoggedInViewProps) => {
  return (
    <UserProvider>
      <EvaluationLicenceProvider>
        <UmcProvider>
          <UserComponents Component={Component} pageProps={pageProps} />
        </UmcProvider>
      </EvaluationLicenceProvider>
    </UserProvider>
  );
};
