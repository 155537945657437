import { useGetPreferencesJsonQuery, useSaveUserPreferencesMutation } from "@generated/graphql";
import { useCallback } from "react";

export const useUserPreferences = () => {
  const {
    data,
    isSuccess,
    isLoading: isPreferencesLoading,
    refetch: refetchPreferences,
    isRefetching: isPreferencesRefetching,
  } = useGetPreferencesJsonQuery();
  const { mutateAsync, isPending: isLoadingMutation } = useSaveUserPreferencesMutation();

  const preferences: Record<string, unknown> = data?.me.preferencesJson;

  const setPreferences = useCallback(
    (settings: unknown) => {
      if (isSuccess) {
        mutateAsync({
          preferencesJson: JSON.stringify({
            ...(preferences ?? {}),
            ...(settings ?? {}),
          }),
        });
      }
      refetchPreferences();
    },
    [isSuccess, preferences, mutateAsync, refetchPreferences],
  );

  return {
    preferences,
    setPreferences,
    isPreferencesLoading,
    isLoadingMutation,
    isPreferencesRefetching,
  };
};
