import { hasRole } from "@components/user-context/helper";
import { UserContext, type UserContextType } from "@components/user-context/user-context";
import { INFINITAS_DOMAIN, INTERNAL_DOMAINS } from "@utils/constants";
import { type OpCo, useBrand } from "@utils/use-brand";
import { useClassrooms } from "@utils/use-classrooms";
import { useContext } from "react";

type CustomTagKeys = "userRole" | "userOrigin" | "organizationId" | "wave" | "classAmount";
type UserRole = "Teacher" | "Admin" | "Publisher";
type UserOrigin = "Internal" | "External";
type OrganizationId = "Infinitas" | "Plantyn NL" | "Plantyn FR" | "Noordhoff" | "Liber" | "Leya"; // Leya is not yet used
type Wave = "Alpha" | "Beta" | "Gamma";
type ClassAmount = "Single" | "Multiple";
type CustomTagValues = UserRole | UserOrigin | OrganizationId | Wave | ClassAmount;

declare global {
  interface Window {
    // biome-ignore lint/complexity/noBannedTypes: we need to use the Clarity object
    clarity?: (method: "set" | "event" | "upgrade", key: string, value?: string | string[]) => {};
  }
}

const getOpCoTag = (
  opCo: OpCo,
  userCtx: UserContextType,
): { key: "organizationId"; value: OrganizationId } | null => {
  // biome-ignore lint/style/noNonNullAssertion: If it's an infinitas employee, we just track infinitas as opCo
  if (userCtx.me.data!.email.includes(INFINITAS_DOMAIN)) {
    return { key: "organizationId", value: "Infinitas" };
  }
  if (opCo === "plantyn") {
    const host = window.location.hostname;
    if (host.includes("leerkracht.scoodle")) {
      return { key: "organizationId", value: "Plantyn NL" };
    }
    if (host.includes("enseignant.scoodle")) {
      return { key: "organizationId", value: "Plantyn FR" };
    }
  }
  if (opCo === "noordhoff") {
    return { key: "organizationId", value: "Noordhoff" };
  }
  if (opCo === "liber") {
    return { key: "organizationId", value: "Liber" };
  }
  return null;
};

export const useClarityTags = () => {
  const userCtx = useContext(UserContext);
  const { opCo } = useBrand();
  const { data: classroomsData } = useClassrooms({ staleTime: Number.POSITIVE_INFINITY });

  if (classroomsData && userCtx && userCtx.me.data) {
    const customTags: { key: CustomTagKeys; value: CustomTagValues }[] = [];

    // handle user type, we do this by checking the user email
    const isInternal = INTERNAL_DOMAINS.some((email) => userCtx.me.data?.email.includes(email));
    customTags.push({ key: "userOrigin", value: isInternal ? "Internal" : "External" });

    // handle roles
    if (userCtx.roles.isTeacher) {
      customTags.push({ key: "userRole", value: "Teacher" });
    }
    if (userCtx.roles.isAdmin) {
      customTags.push({ key: "userRole", value: "Admin" });
    }
    if (userCtx.roles.isPublisher) {
      customTags.push({ key: "userRole", value: "Publisher" });
    }

    // handle opCo
    const opcoTag = getOpCoTag(opCo, userCtx);
    opcoTag && customTags.push(opcoTag);

    // handle waves
    const isAlpha = hasRole(userCtx, "alpha");
    const isBeta = hasRole(userCtx, "beta");
    const isGamma = hasRole(userCtx, "gamma");
    isAlpha && customTags.push({ key: "wave", value: "Alpha" });
    isBeta && customTags.push({ key: "wave", value: "Beta" });
    isGamma && customTags.push({ key: "wave", value: "Gamma" });

    // handle class amount
    classroomsData?.classroomsOfUser.length > 1
      ? customTags.push({ key: "classAmount", value: "Multiple" })
      : customTags.push({ key: "classAmount", value: "Single" });

    return customTags;
  }

  return [];
};
