import Script from "next/script";

type ClarityScriptProps = {
  projectId: string | undefined;
  customTags: { key: string; value: string }[];
};

let initialized = false;

export const ClarityScript: React.FC<ClarityScriptProps> = ({ projectId, customTags }) => {
  const isLocal = process.env.NODE_ENV === "development";
  if (!projectId || isLocal || customTags.length === 0 || initialized) {
    return null;
  }

  const claritySrc = `
    (function(c,l,a,r,i,t,y,tags){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      
      // at this point window.clarity is defined, we can set custom tags
      tags.forEach((tag) => {
        window.clarity?.("set", tag.key, tag.value);
      });
      
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${projectId}", undefined, undefined, ${JSON.stringify(
      customTags,
    )});
  `;

  initialized = true;

  return (
    <>
      <Script
        id="script-clarity"
        strategy="afterInteractive"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: needed for clarity script
        dangerouslySetInnerHTML={{
          __html: claritySrc,
        }}
      />
    </>
  );
};
