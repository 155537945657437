import type { UmcConfig } from "@generated/graphql";

export type UmcConfigSettings = Omit<
  UmcConfig,
  "creatorId" | "disabledLearningPlanIds" | "umcContentUnits"
>;
type REARRANGED = Record<string, string[]>;

type ConfigReducerState = UmcConfigSettings;

type TOGGLE_HIDDEN_PAYLOAD = {
  itemId: string;
  parentId?: string;
  childrenIds?: string[];
  otherItems?: Array<{ id: string; hidden?: boolean }>;
};

export type HIDE_ITEMS = {
  type: "HIDE_ITEMS";
  payload: TOGGLE_HIDDEN_PAYLOAD;
};

export type SHOW_ITEMS = {
  type: "SHOW_ITEMS";
  payload: TOGGLE_HIDDEN_PAYLOAD;
};

export type REARRANGE = {
  type: "REARRANGE";
  payload: {
    rearranged: Record<string, string[]>;
  };
};

export type RESET_ALL_CHANGES = {
  type: "RESET_ALL_CHANGES";
};

type RESET = {
  type: "RESET";
  payload: {
    config: UmcConfigSettings;
  };
};

type RESET_ITEM_ORDER = {
  type: "RESET_ITEM_ORDER";
  payload: {
    itemId: string;
  };
};

export type ACTIONS =
  | SHOW_ITEMS
  | HIDE_ITEMS
  | REARRANGE
  | RESET_ALL_CHANGES
  | RESET
  | RESET_ITEM_ORDER;

const getItemsToShow = ({ itemId, childrenIds = [], parentId }: TOGGLE_HIDDEN_PAYLOAD) => [
  itemId,
  ...childrenIds,
  ...((parentId && [parentId]) || []),
];

const getItemsToHide = ({
  itemId,
  childrenIds = [],
  otherItems = [],
  parentId,
}: TOGGLE_HIDDEN_PAYLOAD) => [
  itemId,
  ...childrenIds,
  ...(otherItems.every((item) => item.hidden) && parentId ? [parentId] : []),
];

export const umcConfigReducer = (
  state: ConfigReducerState,
  action: ACTIONS,
): ConfigReducerState => {
  switch (action.type) {
    case "HIDE_ITEMS":
      return {
        ...state,
        hidden: [...new Set([...state.hidden, ...getItemsToHide(action.payload)])],
      };
    case "SHOW_ITEMS": {
      const itemsToShow = getItemsToShow(action.payload);
      return {
        ...state,
        hidden: state.hidden.filter((hiddenId) => !itemsToShow.includes(hiddenId)),
      };
    }
    case "REARRANGE":
      return {
        ...state,
        rearranged: { ...state.rearranged, ...action.payload.rearranged },
      };
    case "RESET_ALL_CHANGES": {
      return {
        ...state,
        hidden: [],
        rearranged: {},
      };
    }
    case "RESET":
      return action.payload.config;
    case "RESET_ITEM_ORDER": {
      return {
        ...state,
        rearranged: Object.keys(state.rearranged)
          .filter((key) => key !== action.payload.itemId)
          .reduce<REARRANGED>((rearranged, key) => {
            rearranged[key] = state.rearranged[key];
            return rearranged;
          }, {}),
      };
    }
    default:
      return state;
  }
};
