import { useUserPreferences } from "./use-user-preferences";
import { useContext, useMemo } from "react";
import { UserContext } from "@components/user-context/user-context";
import { savedSubmissionsSchema, type SavedSubmissions } from "@infinitaslearning/survey";
import { getLegacyCSAT, getLegacyNPS } from "./getLegacySubmission";

const NEW_SETTINGS_KEY = "SubmissionsShownDate";

// This module abstracts the logic of preferences read/write
// returns the value and sateValue to remember when a form has been already submitted
// can read the value both in the legacy format and the new one
// writes only in the new format
export const useSubmissionDate = ({
  configNPS,
  configCSATQ,
  configCSATU,
}: {
  configNPS: { formId: string } | undefined | null;
  configCSATQ: { formId: string } | undefined | null;
  configCSATU: { formId: string } | undefined | null;
}) => {
  // extract from ids from config
  const NPSFormId = configNPS?.formId ?? "-";
  const CSATQFormId = configCSATQ?.formId ?? "-";
  const CSATUFormId = configCSATU?.formId ?? "-";

  const { preferences, setPreferences } = useUserPreferences();
  const userContext = useContext(UserContext);
  const userId = userContext?.me.data?.id ?? "-";

  const value: SavedSubmissions | undefined = useMemo(() => {
    if (!preferences) {
      return {};
    }
    const pref = preferences[NEW_SETTINGS_KEY];
    const currentValue = savedSubmissionsSchema.safeParse(pref).data;
    if (currentValue) {
      return currentValue;
    }

    // if we have no value set, we also look into the legacy preferences
    const NPSDate = getLegacyNPS(preferences, userId);
    const CSATQDate = getLegacyCSAT(preferences, userId, CSATQFormId);
    const CSATUDate = getLegacyCSAT(preferences, userId, CSATUFormId);

    return {
      [NPSFormId]: NPSDate,
      [CSATQFormId]: CSATUDate,
      [CSATUFormId]: CSATQDate,
    };
  }, [preferences, userId, NPSFormId, CSATQFormId, CSATUFormId]);

  const setter = useMemo(
    () => (payload: Record<string, string>) => {
      setPreferences({
        [NEW_SETTINGS_KEY]: payload,
      });
    },
    [setPreferences],
  );

  return {
    savedSubmissions: value,
    setSubmissions: setter,
  };
};
