import type { UmcConfigSettings } from "@components/umc-config-context/umc-reducer";
import type { UmcConfigsQuery } from "@generated/graphql";
import { createContext, useContext } from "react";

export type UmcConfigsMap = Record<string, { umcConfigId: string; umcConfigUpdatedAt: number }>;

export type UmcContextType = {
  umcConfigs: UmcConfigsQuery["umcConfigs"];
  methodGroupIds: string[];
  umcConfigsMap: UmcConfigsMap;
  umcConfigInEditMode: UmcConfigSettings | null;
  setUmcConfigInEditMode: (data: UmcConfigSettings | null) => void;
  /**
   * Function to refetch UMC configs and related methods.
   * To be used after saving or deleting UMC configs, to refresh
   * visible/hidden elements in the UI.
   *
   * This function make sure that the correct UMC headers are present in the
   * GetMethodFromGroupsToLearningUnits query request.
   */
  refetchUmcConfigsAndMethod: () => Promise<void>;
};

export const UmcContext = createContext<UmcContextType>({
  umcConfigs: [],
  methodGroupIds: [],
  umcConfigsMap: {},
  umcConfigInEditMode: null,
  setUmcConfigInEditMode: () => null,
  refetchUmcConfigsAndMethod: () => Promise.resolve(),
});

export const useUmcContext = () => {
  const context = useContext(UmcContext);

  if (!context) {
    throw new Error("useUmcContext must be used within a UmcProvider");
  }

  return context;
};
