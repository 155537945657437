import { type PropsWithChildren, useReducer, useEffect } from "react";
import { UmcConfigContext } from "@components/umc-config-context/umc-config-context";
import {
  umcConfigReducer,
  type UmcConfigSettings,
} from "@components/umc-config-context/umc-reducer";

export const UmcConfigProvider = ({
  children,
  initialConfig,
}: PropsWithChildren<{ initialConfig: UmcConfigSettings }>) => {
  const [umcConfig, dispatch] = useReducer(umcConfigReducer, initialConfig);

  useEffect(() => {
    if (initialConfig.id !== umcConfig.id) {
      dispatch({ type: "RESET", payload: { config: initialConfig } });
    }
  }, [initialConfig, umcConfig.id]);

  return (
    <UmcConfigContext.Provider
      value={{
        umcConfig,
        initialConfig,
        dispatch,
      }}
    >
      {children}
    </UmcConfigContext.Provider>
  );
};
