import { isUserAlphaOrBeta, isUserGamma } from "./utils";

import type { FeatureFlag } from "@components/user-context/user-context";
import type { MeQuery } from "@generated/graphql";
import type { OpCo } from "@utils/use-brand";
import type { TFunction } from "next-i18next";
import type { User } from "configcat-common";
import { getConfigCatClient } from "./getConfigcatClient";

export const getAllValues = async (
  opCo: OpCo,
  meData?: MeQuery["me"] | undefined,
  custom: object = {},
  configTranslation?: TFunction,
  cookie?: { key: string; value: string }[],
): Promise<FeatureFlag[]> => {
  const configCatClient = getConfigCatClient(opCo);

  let userObject: User;
  if (meData) {
    const roles = meData.roles.map((role) => `<${role}>`).toString();
    userObject = { identifier: meData.id, custom: { roles, ...custom } };
  } else {
    const roles: string = "[]";
    userObject = { identifier: "", custom: { roles, ...custom } };
  }
  const settingKeyValues = (await configCatClient?.getAllValueDetailsAsync(userObject)) ?? [];

  // configcat stores a particular option "gammaWaveFeatureList" that includes
  // all the feature flag ids allowed to be shown in the gamma wave program
  const gammaFeatureFlags = JSON.parse(
    (settingKeyValues.find((f) => f.key === "gammaWaveFeatureList")?.value as string) ?? "[]",
  );

  const featureFlags = settingKeyValues.map((flag) => {
    // Check if we have a translation/description for a key
    // if we do it is a toggle and we use the cookie value (if set)
    let isToggleable = false;
    let value = flag.value;
    let isGammaWaveFeature = false;
    if (
      configTranslation &&
      flag.value === true &&
      flag.matchedTargetingRule &&
      configTranslation(`toggles.${flag.key}.name`, "") !== ""
    ) {
      if (
        isUserAlphaOrBeta(meData) ||
        (isUserGamma(meData) && gammaFeatureFlags.includes(flag.key))
      ) {
        isToggleable = true;
        value = cookie?.find((x) => x.key === flag.key)?.value || false;
        isGammaWaveFeature = gammaFeatureFlags.includes(flag.key);
      }
    }

    return {
      key: flag.key,
      value,
      isToggleable,
      isGammaWaveFeature,
    };
  });

  return featureFlags;
};
