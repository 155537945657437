import type { ACTIONS, UmcConfigSettings } from "@components/umc-config-context/umc-reducer";
import { type Dispatch, createContext, useContext } from "react";

type UmcConfigContextType = {
  umcConfig: UmcConfigSettings;
  initialConfig: UmcConfigSettings;
  dispatch: Dispatch<ACTIONS>;
};

const emptyUmcConfig = {
  id: "",
  versionName: "",
  packageId: "",
  groupIds: [],
  hidden: [],
  rearranged: {},
  updatedAt: 0,
  disabledLearningPlanIds: [],
  umcContentUnits: [],
};

export const UmcConfigContext = createContext<UmcConfigContextType>({
  umcConfig: emptyUmcConfig,
  initialConfig: emptyUmcConfig,
  dispatch: () => null,
});

export const useUmcConfigContext = () => {
  const context = useContext(UmcConfigContext);

  if (!context) {
    throw new Error("useUmcConfigContext must be used within a UmcConfigProvider");
  }

  return context;
};
