import { type PropsWithChildren, useState } from "react";
import { type UmcConfigsMap, UmcContext } from "@components/umc-context/umc-context";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useGetMethodWithMethodGroupsQuery, useUmcConfigsQuery } from "@generated/graphql";
import { useRouter } from "next/router";
import { Loading } from "@components/loading";
import { UmcConfigProvider } from "@components/umc-config-context/umc-config-provider";
import { NEW_UMC_CONFIG_ID } from "@utils/umc-constants";
import type { UmcConfigSettings } from "@components/umc-config-context/umc-reducer";
import { queryClient } from "@lib/react-query";

export const UmcProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const { method, versionId } = router.query;
  const currentMethodGroup = router.query["method-group"] as string;
  const useUmcAnnualPreparation = useFeatureFlag("umcAnnualPreparation", false);
  const [umcConfigInEditMode, setUmcConfigInEditMode] = useState<UmcConfigSettings | null>(null);

  const { data: methodData, isLoading: methodDataLoading } = useGetMethodWithMethodGroupsQuery(
    {
      methodId: method as string,
    },
    {
      enabled: method !== undefined,
    },
  );

  const methodGroupIds =
    methodData?.method?.methodGroups?.map((methodGroup) => methodGroup.id) ?? [];

  const { data, isLoading: umcConfigsLoading } = useUmcConfigsQuery(
    {
      packageIds: methodGroupIds,
    },
    {
      enabled: methodGroupIds?.length > 0,
    },
  );

  if (methodDataLoading || umcConfigsLoading) {
    return <Loading />;
  }

  if (!useUmcAnnualPreparation) {
    return <>{children}</>;
  }

  const umcConfigs = data?.umcConfigs ?? [];
  const umcConfigsMap = umcConfigs.reduce<UmcConfigsMap>((acc, umcConfig) => {
    const currentVersionForCurrentPackage = umcConfigs.find(
      (umcConfig) => umcConfig.id === versionId && umcConfig.packageId === currentMethodGroup,
    );
    acc[umcConfig.packageId] = {
      umcConfigId: currentVersionForCurrentPackage?.id || umcConfig.id,
      umcConfigUpdatedAt: currentVersionForCurrentPackage?.updatedAt || umcConfig.updatedAt,
    };
    return acc;
  }, {});

  const initialConfig = umcConfigInEditMode || {
    id: NEW_UMC_CONFIG_ID,
    versionName: "",
    packageId: "",
    hidden: [],
    rearranged: {},
    updatedAt: 0,
    groupIds: [],
  };

  const refetchUmcConfigsAndMethod = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["UmcConfigs"],
    });
  };

  return (
    <UmcConfigProvider initialConfig={initialConfig}>
      <UmcContext.Provider
        value={{
          umcConfigs,
          methodGroupIds,
          umcConfigsMap,
          umcConfigInEditMode,
          setUmcConfigInEditMode,
          refetchUmcConfigsAndMethod,
        }}
      >
        {children}
      </UmcContext.Provider>
    </UmcConfigProvider>
  );
};
