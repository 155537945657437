import { hasRole } from "@components/user-context/helper";
import { UserContext } from "@components/user-context/user-context";
import {
  type GetMethodFromGroupsToLearningUnitsQuery,
  type GetSubjectsAndMethodsQuery,
  useGetMethodFromGroupsToLearningUnitsQuery,
  useGetSubjectsAndMethodsQuery,
} from "@generated/graphql";
import { REACT_QUERY_OPTS_15MINSTALE } from "@lib/react-query";
import { slugify } from "@utils/slugify";
import { useBrand, type OpCo } from "@utils/use-brand";
import { useCallback, useContext, useEffect } from "react";

type UsetifulTagsObj = {
  userId: string;
  teacherOnlyContent: boolean;
  language: string;
  organizationId: string;
  opCo: OpCo;
  methodIds: string[];
  waves: string[];
  roles: string[];
  methodPath: string | undefined;
  methodGroupPath: string | undefined;
  chapterPath: string | undefined;
  paragraphPath: string | undefined;
  learningUnitPath: string | undefined;
};

type UsetifulTourLinkPathName = "method" | "chapter" | "paragraph" | "learningUnit" | "methodGroup";

const USETIFUL_SCRIPT_ID = "script-usetiful";

const getUsetifulSrc = (usetifulTagsObj: UsetifulTagsObj): string => {
  return `
    window.usetifulTags = ${JSON.stringify(usetifulTagsObj)};
    (function (w, d, s) {
        var a = d.getElementsByTagName('head')[0];
        var r = d.createElement('script');
        r.async = 1;
        r.src = s;
        r.setAttribute('id', 'usetifulScript');
        r.dataset.token = "3e2b897351ccfd13c217afbf1aabd2c7";
        a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");`;
};

const getUsetifulTourLinks =
  (
    subjectsAndMethods: GetSubjectsAndMethodsQuery | undefined,
    methodData: GetMethodFromGroupsToLearningUnitsQuery | undefined,
  ) =>
  (pathName: UsetifulTourLinkPathName) => {
    if (methodData && subjectsAndMethods) {
      switch (pathName) {
        case "method":
          return `${slugify(subjectsAndMethods?.subjects[0].name).toLowerCase()}/${
            subjectsAndMethods?.subjects[0].methodsLicensed[0].id
          }`;
        case "methodGroup":
          // @ts-ignore
          return methodData.method?.methodGroups[0].id;
        case "chapter":
          // @ts-ignore
          return methodData?.method?.methodGroups[0].chapters[0].id;
        case "paragraph":
          // @ts-ignore
          return methodData?.method?.methodGroups[0].chapters[0].paragraphs[0].id;
        case "learningUnit":
          // @ts-ignore
          return methodData?.method?.methodGroups[0].chapters[0].paragraphs[0].learningUnits[0].id;
        default:
          return "";
      }
    }
    return "";
  };

export const hasAllTeacherOnlyMethods = (subjectsAndMethods: GetSubjectsAndMethodsQuery) => {
  return !subjectsAndMethods.subjects.some((subject) => {
    return subject.methodsLicensed.some((method) => !method.isTeacherOnly);
  });
};

export const useUsetiful = () => {
  const userContext = useContext(UserContext);
  const userId = userContext?.me.data?.id;
  const organizationId = userContext?.me.data?.organizationId;
  const { locale, opCo } = useBrand();

  const { data: subjectsAndMethods } = useGetSubjectsAndMethodsQuery(
    undefined,
    REACT_QUERY_OPTS_15MINSTALE,
  );

  const firstMethodLicensedId = subjectsAndMethods?.subjects?.[0]?.methodsLicensed?.[0].id;

  const { data: methodData } = useGetMethodFromGroupsToLearningUnitsQuery(
    { id: firstMethodLicensedId || "", withDomains: false },
    { enabled: !!firstMethodLicensedId, ...REACT_QUERY_OPTS_15MINSTALE },
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const getLink = useCallback(getUsetifulTourLinks(subjectsAndMethods, methodData), [
    subjectsAndMethods,
    methodData,
  ]);

  useEffect(() => {
    const existingScript = document.getElementById(USETIFUL_SCRIPT_ID);

    if (!userId || !subjectsAndMethods || !methodData || !organizationId || existingScript) {
      return;
    }

    const scriptSrc = getUsetifulSrc({
      userId,
      teacherOnlyContent: hasAllTeacherOnlyMethods(subjectsAndMethods),
      methodIds: subjectsAndMethods?.subjects.flatMap((subject) =>
        subject.methodsLicensed.map((method) => method.id),
      ),
      language: locale,
      organizationId,
      opCo,
      waves: [
        hasRole(userContext, "alpha") ? "alpha" : "",
        hasRole(userContext, "beta") ? "beta" : "",
        hasRole(userContext, "gamma") ? "gamma" : "",
      ].filter(Boolean),
      roles: [
        userContext.roles.isTeacher ? "teacher" : "",
        userContext.roles.isAdmin ? "admin" : "",
        userContext.roles.isPublisher ? "publisher" : "",
      ].filter(Boolean),
      methodPath: getLink("method"),
      methodGroupPath: getLink("methodGroup"),
      chapterPath: getLink("chapter"),
      paragraphPath: getLink("paragraph"),
      learningUnitPath: getLink("learningUnit"),
    });
    const script = document.createElement("script");
    script.id = USETIFUL_SCRIPT_ID;
    script.text = scriptSrc;
    document.body.appendChild(script);
  }, [userContext, userId, subjectsAndMethods, locale, opCo, organizationId, getLink, methodData]);
};
