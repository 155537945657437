import { subMonths } from "date-fns";
import * as z from "zod";

const LEGACY_NPS_SETTINGS_KEY = "tallyShowAfterDate";
const LEGACY_CSAT_SETTINGS_KEY = "tallyShownInfo";

// legacy format for NPS:
//   { [user-id]: date }
const zodLegacyNPSSub = z.record(z.string(), z.string());
export type LegacyNPSSub = z.infer<typeof zodLegacyNPSSub>;

export function getLegacyNPS(preferences: Record<string, unknown>, userId: string) {
  const legacyValueNPS: LegacyNPSSub | undefined =
    zodLegacyNPSSub.safeParse(preferences[LEGACY_NPS_SETTINGS_KEY]).data ?? {};
  const date = legacyValueNPS[userId];

  if (!date) {
    return "";
  }
  // NOTE dates were stored two months in the future
  const result = subMonths(new Date(date), 2);

  return new Date(result).toISOString();
}

// legacy format for CSAT:
// userId: {
//   shownOn: date
//   formId: string
// }
const zodLegacyCSATSub = z.record(
  z.string(),
  z.object({
    shownOn: z.string(),
    formId: z.string(),
  }),
);
export type LegacyCSATSub = z.infer<typeof zodLegacyCSATSub>;

export function getLegacyCSAT(
  preferences: Record<string, unknown>,
  userId: string,
  CSATFormId: string,
) {
  const legacyValueCSAT: LegacyCSATSub | undefined =
    zodLegacyCSATSub.safeParse(preferences[LEGACY_CSAT_SETTINGS_KEY]).data ?? {};

  if (!legacyValueCSAT || !legacyValueCSAT[userId]) {
    return "";
  }
  const { shownOn, formId } = legacyValueCSAT[userId];

  // not this form
  if (CSATFormId !== formId) {
    return "";
  }

  return new Date(shownOn).toISOString();
}
