import { useEffect } from "react";
import { Workbox } from "workbox-window";
import { getApmWeb } from "@lib/observability";
import { unknownToError } from "@utils/common";

const ERROR_LOCATION = "use-service-worker";

const unregisterServiceWorker = async () => {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations.length > 0) {
      // biome-ignore lint/complexity/noForEach: forEach is used to unregister the service workers
      registrations.forEach((r) => r.unregister());
    }
  } catch (e) {
    getApmWeb().captureError(unknownToError(e), ERROR_LOCATION, {
      info: "There was an issue un-registering the service worker.",
    });
  }
};

const registerServiceWorker = async (wb: Workbox) => {
  try {
    const registration = await wb.register();
    if (registration)
      registration.installing?.addEventListener("statechange", (event) => {
        if ((event.target as ServiceWorker).state === "redundant") {
          // runtime error in the service worker prior to finishing the install step
          // this error will not be caught by the try-catch as it happens after the registration
          getApmWeb().captureError(
            new Error("There was an issue installing the service worker."),
            ERROR_LOCATION,
          );
        }
      });
  } catch (e) {
    getApmWeb().captureError(unknownToError(e), ERROR_LOCATION, {
      info: "There was an issue registering the service worker.",
    });
  }
};

const useServiceWorker = () => {
  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      // The browser does not support service workers, nothing we can do
      return;
    }

    if (process.env.NEXT_PUBLIC_ENABLE_SERVICE_WORKER?.toLowerCase() !== "true") {
      unregisterServiceWorker();
      return;
    }

    const wb = new Workbox("/sw.js", { scope: "/" });

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate
    wb.addEventListener("waiting", () => {
      wb.messageSkipWaiting();
    });

    registerServiceWorker(wb);
  }, []);
};

export default useServiceWorker;
