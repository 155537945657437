import type { Session } from "next-auth";

type StatusType = "authenticated" | "loading" | "unauthenticated";
type SessionType = Session | null;

let status: StatusType = "unauthenticated";
let session: SessionType = null;

export const getLocalSession = (): { status: StatusType; session: SessionType } => {
  return { status, session };
};

export const setLocalSession = (ses: Session | null, stat: StatusType) => {
  status = stat;
  session = ses;
};
